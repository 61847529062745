.contact .php-email-form .custom-input input  {
    padding : 0px 10px !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border: 1px solid #ced4da !important;
    border-color: #ced4da !important;
  }
  
  
.invalid{
  border:1px solid #ff0000;
  }


.MuiOutlinedInput-notchedOutline{
  border-color: #ced4da !important;
}

.invalid .MuiOutlinedInput-notchedOutline{
  border:1px solid #ff0000 !important;
}


.next {
  background-color: #c62123;
  border:1px outset #FFF !important;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 18px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.previous {
  background-color: #1c293a;
  border:1px outset #FFF !important;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 18px;
  margin-left: 10px;
  margin-bottom: 10px;
}

input[type=checkbox] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin-right: 5px;
}

label {
  display: block;
}





